import fetchWrap from 'fetch-wrap';
import { updateData, logoutUser } from './redux/authSlice';
// import { store } from './store';

const baseUrl = process.env.REACT_APP_APIURL;

const getClient = () => {
  // console.log('procee.env', process.env);
  if (process.env.REACT_APP_CLIENT) {
    console.log('client is from react is', process.env.REACT_APP_CLIENT);
    return process.env.REACT_APP_CLIENT;
  }
  // this handles dev and prod
  const client = window.location.hostname.match(/((\w+)(-ars20)?)\.[a-zA-Z-]+\.com/);

  if (client && client[2]) {
    console.log('client from url is', client[2]);
    return client[2];
  }
  return null;
};

const checkAuth = () => {
  return async (url, options, fetch) => {
    // eslint-disable-next-line global-require
    const { store } = require('./store');
    const { auth } = store.getState();

    if (!options.headers) {
      // console.log('url', url, 'in here', auth);
      store.dispatch(logoutUser());
      window.location.href = '/auth/login';
    }

    const response = await fetch(url, options);
    if (![401, 403].includes(response.status)) {
      return response;
    }
    // the current user is unauthorized.
    // lets try to refresh the token

    if (!auth.refreshToken) {
      store.dispatch(logoutUser());
      window.location.href = '/auth/login';
    }
    // lets try to refresh the access token
    const refreshUrl = `${baseUrl}/auth/refresh`;
    const data = {
      method: 'POST',
      headers: auth.headers,
      body: JSON.stringify({ refreshToken: auth.refreshToken })
    };
    const refreshResponse = await fetch(refreshUrl, data);
    if ([401, 403].includes(refreshResponse.status)) {
      // the refresh token must be expired too.
      // log out the user and redirect to the login page.
      store.dispatch(logoutUser());
      window.location.href = '/auth/login';
    }
    const refreshJson = await refreshResponse.json();

    // create/update the new headers
    const headers = {
      ...auth.headers,
      Authorization: `Bearer ${refreshJson.accessToken}`
    };

    // dispatch the refresh token action to update the redux store
    store.dispatch(
      updateData({
        accessToken: refreshJson.accessToken,
        refreshToken: refreshJson.refreshToken,
        headers: headers
      })
    );
    return fetch(url, { ...options, headers: headers });
  };
};

console.log('process.env', process.env);
console.log('baseUrl', baseUrl);

const config = {
  baseUrl: process.env.REACT_APP_APIURL || '',
  fetch: fetchWrap(fetch, [checkAuth()]),
  clientDomainRgx: new RegExp(/presenterdashboard\.com|va-beta\.com|localhost/),
  client: getClient(),
  sessionStatus: {
    openStatus: 1,
    closedStatus: 3,
    deletedStatus: 4
  }
};

export default config;
