import React from 'react';
import { Button, Modal, Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { updateSession } from '../../redux/dataSlice';

const SessionStatusModal = ({ open, handleClose, selectedSession }) => {
  const dispatch = useDispatch();
  const openButton = () => {
    dispatch(updateSession({ ...selectedSession, status: selectedSession.status + 1 }));
    handleClose();
  };

  const sessionStatusIsCurrentlyOpen = selectedSession?.status === 2;

  return (
    <Modal open={open} onClose={handleClose} disableScrollLock>
      <Box className="modal-context">
        <Box>
          <p className="modal-header">
            {sessionStatusIsCurrentlyOpen ? 'Close the Session?' : 'Open the Session?'}
          </p>
          <p>
            {sessionStatusIsCurrentlyOpen
              ? 'You will no longer be able to interact with this session. Are you sure you want to close?'
              : 'Your testing data wont be saved. Are you sure you want to make public?'}
          </p>
          <div className="modal-open-session-buttons">
            <Button variant={'contained'} sx={{ width: '45%' }} onClick={() => handleClose()}>
              Cancel
            </Button>
            <Button variant={'outlined'} sx={{ width: '45%' }} onClick={openButton}>
              {sessionStatusIsCurrentlyOpen ? 'Close Session' : 'Yes, Open'}
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default SessionStatusModal;
