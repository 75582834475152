import React, { useState, useEffect, useContext } from 'react';
import { useParams } from 'react-router-dom';
import io from 'socket.io-client';
import _ from 'lodash';

import config from '../config';
import { getSlideByID } from '../utils/utils';
import LoadingComponent from '../common/LoadingComponent';
// console.log('slidesConfigData', slidesConfigData);

const PresenterOrAttendeeContext = React.createContext(null);

export const AttendeeAndPresenterProvider = ({ children, type }) => {
  const { sessionID } = useParams();
  const [activeSession, setActiveSession] = useState(null);
  const [isSessionStarted, setIsSessionStarted] = useState(false);
  const [isSessionComplete, setIsSessionComplete] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const [isForwardBackActive, setIsForwardBackActive] = useState(true);
  const [slidesConfig, setSlidesConfig] = useState();
  const [socket, setSocket] = useState();
  const [connected, setConnected] = useState(false);
  const [currentPollingStatus, setCurrentPollingStatus] = useState(0);
  const [extraContentToRender, setExtraContentToRender] = useState(null);
  const [attendeeStyles, setAttendeeStyles] = useState(null);
  const [customClientDomain, setcustomClientDomain] = useState(null);
  const [questionsLoaded, setQuestionsLoaded] = useState(null);

  useEffect(() => {
    // we need to emit a get session
    const newSocket = io.connect(config.baseUrl);
    setSocket(newSocket);

    newSocket.on('connect', () => {
      console.log('connecting and joining room', `${sessionID}|${type}`);
      newSocket.emit('join', `${sessionID}|${type}`);
      newSocket.emit('getSession', { sessionID });
      setConnected(true);
    });

    newSocket.on('sessionRetrieved', (session) => {
      if (session) {
        const slide = getSlideByID(session.ConfigData, session.slideConfigID);
        console.log(session);
        setActiveSession(session);

        if (session.status === 2) {
          setIsSessionStarted(true);
        }

        if (session.status === 3) {
          setIsSessionComplete(true);
        }

        setCurrentSlide(slide);
        setSlidesConfig(session.ConfigData);
        setcustomClientDomain(session.customClientDomain);
        if (type === 'attendee') {
          setAttendeeStyles(session.AttendeeStyles);
        }
      }
    });

    newSocket.on('retrievePollingState', (result) => {
      console.log('RETREIVED POLLING STATE', result);
      setCurrentPollingStatus(result?.pollingStatus);
    });

    if (type === 'attendee') {
      newSocket.on('sessionEndedByPresenter', (emptyResult) => {
        setIsSessionComplete(true);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (socket && slidesConfig) {
      socket.on('beginSessionComplete', () => {
        setCurrentSlide(getSlideByID(slidesConfig, 0));
        setIsSessionStarted(true);
      });

      socket.on('action', (payload) => {
        console.log('Payload', payload);

        if (payload.actionType === 'seekedSlide') {
          setIsForwardBackActive(!payload.backForwardButtonDisabled);
          setCurrentSlide(getSlideByID(slidesConfig, payload.id));
        }

        if (payload.actionType === 'pollingStatusUpdate') {
          console.log('POLLING STATUS UPDATE', payload);
          setCurrentSlide(getSlideByID(slidesConfig, payload.slideConfigID));
          setCurrentPollingStatus(payload.pollingStatus);
        }

        if (payload.actionType === 'videoToggle') {
          setIsVideoPlaying(payload.videoPlaying);
        }

        if (payload.actionType === 'toggleForwardBackButtons') {
          setIsForwardBackActive(payload.state);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, slidesConfig]);

  useEffect(() => {
    if (socket && currentSlide?.isPolling) {
      // TODO MERGE INTO 1. Send down type
      socket.emit('getPollingResults', { slideConfigID: currentSlide.id, sessionID });
      socket.emit('getPollingState', { sessionID, slideConfigID: currentSlide.id });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [socket, currentSlide]);

  const pollingEnabled = currentSlide?.isPolling;

  if (connected) {
    return (
      <PresenterOrAttendeeContext.Provider
        value={{
          activeSession,
          pollingEnabled,
          currentSlide,
          setCurrentSlide,
          setIsSessionStarted,
          isSessionStarted,
          sessionID,
          slidesConfig,
          setCurrentPollingStatus,
          currentPollingStatus,
          isVideoPlaying,
          setIsVideoPlaying,
          extraContentToRender,
          attendeeStyles,
          customClientDomain,
          isSessionComplete,
          setIsSessionComplete,
          isForwardBackActive,
          setIsForwardBackActive,
          questionsLoaded,
          setQuestionsLoaded,
          socket
        }}
      >
        {children}
      </PresenterOrAttendeeContext.Provider>
    );
  }
  return <LoadingComponent />;
};

export const usePresenterOrAttendee = () => {
  const store = useContext(PresenterOrAttendeeContext);
  return store;
};
