import { Button, TextField } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { loginUser } from '../../../redux/authSlice';
import LoadingComponent from '../../../common/LoadingComponent';

const LoginPage = () => {
  const { register, handleSubmit } = useForm();
  const [showPassword, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.auth.loading);
  const error = useSelector((state) => state.auth.error);

  const sendLoginForm = (data) => {
    console.log(data);
    dispatch(loginUser(data.email, data.password, navigate));
  };

  return (
    <form onSubmit={handleSubmit((data) => sendLoginForm(data))} style={{ height: '100%' }}>
      <div className="regPage-wrapper flex-center direction-column">
        <p className="error-text">{error}</p>
        <TextField id="username" label="Username" variant="outlined" {...register('email')} />
        <TextField
          id="password"
          label="Password"
          variant="outlined"
          type={showPassword ? 'text' : 'password'}
          {...register('password')}
        />
        {loading ? (
          <LoadingComponent />
        ) : (
          <Button variant={'contained'} type="submit">
            Login
          </Button>
        )}
      </div>
    </form>
  );
};

export default LoginPage;
