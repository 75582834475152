import { LinearProgress } from '@mui/material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import LoadingComponent from '../../../common/LoadingComponent';
import { usePresenterOrAttendee } from '../../../context/AttendeeAndPresenterProvider';

const PollingResults = () => {
  // This will be gathered from DB
  const [currentResults, setCurrentResults] = useState(null);
  const [totalVotes, setTotalVotes] = useState(0);

  const { currentSlide, sessionID, socket } = usePresenterOrAttendee();

  useEffect(() => {
    console.log('CURRENT SLIDE', currentSlide);

    if (currentSlide?.isPolling) {
      socket.emit('getPollingResults', { slideConfigID: currentSlide.id, sessionID });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSlide]);

  useEffect(() => {
    socket.on('pollingResults', (pollingData) => {
      const resultDict = {};
      currentSlide.content.forEach((element) => {
        resultDict[element.id] = {
          id: element.id,
          text: element.text,
          percentage: _.find(pollingData.results, { id: element.id.toString() })?.percentage || 0.0
        };
      });

      setCurrentResults(_.values(resultDict));
      setTotalVotes(pollingData.totalVotes);
    });
  }, [currentSlide, socket]);

  const displayPollingResults = () => {
    if (currentResults) {
      return (
        <div style={{ marginTop: '1vh' }} className="polling-display-results">
          {currentResults.map((option) => (
            <div key={option.id}>
              <p>
                {option.text}: {Math.floor(option.percentage)}%
              </p>
              <LinearProgress variant="determinate" value={option.percentage} />
            </div>
          ))}
          <p>Total Votes: {totalVotes} </p>
        </div>
      );
    }
    return <LoadingComponent />;
  };

  return displayPollingResults();
};

export default PollingResults;
