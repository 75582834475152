import React, { useState, useEffect } from 'react';
import { Button, Stack } from '@mui/material';
import _ from 'lodash';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import {
  ArrowForwardIos,
  ArrowBackIos,
  PauseCircleOutlineOutlined,
  PlayCircleFilledWhiteOutlined,
  Forward10,
  Replay10
} from '@mui/icons-material';

import Sidebar from './pollingSidebar/Sidebar';
import ViewModalButton from '../../common/ViewModalButton';
import { getSlideByID } from '../../utils/utils';
import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';
import LoadingComponent from '../../common/LoadingComponent';

import styles from '../../styles/PresenterContoller.module.scss';

const ControllerSlide = ({
  updateSelectedOption,
  videoNavigationHandler,
  toggleVideoPlaying,
  skipInVideo
}) => {
  const {
    currentSlide,
    sessionID,
    slidesConfig,
    pollingEnabled,
    isVideoPlaying,
    isForwardBackActive,
    questionsLoaded
  } = usePresenterOrAttendee();

  const [sideBarOpen, setSideBarOpen] = useState(true);
  const [activeButton, setActiveButton] = useState(null);
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(pollingEnabled);

  useEffect(() => {
    console.log('USE EFFECT');
    if (currentSlide.content.length > 0 && !currentSlide.content[0].optionId) {
      videoNavigationHandler(currentSlide, true);
    }
  }, []);

  useEffect(() => {
    setIsNextButtonDisabled(pollingEnabled);
  }, [currentSlide, pollingEnabled]);

  const renderBottomNavigationButtons = () => {
    const navButtons = currentSlide.bottomNavigation?.map((content, key) => {
      const bottomNavSlide = getSlideByID(slidesConfig, content.relatedSlideId);
      // console.log('bottomNavSlide', bottomNavSlide);
      bottomNavSlide.autoPlay = true;

      const leftArrow = content.direction === 'left';

      return (
        <div className={styles['controller-slide']} key={key}>
          <Button
            disabled={key === 1 && isNextButtonDisabled}
            variant={'contained'}
            style={{ width: '100%' }}
            onClick={() => {
              updateSelectedOption(activeButton).then(() => {
                videoNavigationHandler(bottomNavSlide);
                setActiveButton(null);
              });
            }}
          >
            {leftArrow && <ArrowBackIos />}
            {bottomNavSlide.title}
            {!leftArrow && <ArrowForwardIos />}
          </Button>
          <p>{bottomNavSlide.header}</p>
        </div>
      );
    });
    return navButtons;
  };

  const renderQuestionProgressButtons = () => {
    return (
      <div>
        {activeButton?.assocSlides?.map((slide, key) => {
          return (
            <Button
              key={key}
              size={'small'}
              variant={'outlined'}
              style={{
                width: '100%',
                marginTop: 15,
                backgroundColor: activeButton.activeSubButton === key ? '#d4d4d4' : ''
              }}
              onClick={() => {
                videoNavigationHandler({ ...slide, autoPlay: true });
                setActiveButton({ ...activeButton, activeSubButton: key });
              }}
            >
              {slide.title}
            </Button>
          );
        })}
      </div>
    );
  };

  const renderCenterButtons = () => {
    if (currentSlide.content.length > 0 && !questionsLoaded) return <LoadingComponent />;

    if (currentSlide.showNextButtonOnly) {
      return (
        <Button
          variant={'outlined'}
          style={{ width: '50%', margin: 'auto' }}
          onClick={() => {
            toggleVideoPlaying(true);
          }}
        >
          Continue
        </Button>
      );
    }

    return currentSlide.content.map((item, key) => {
      const isActive = activeButton?.id === item.id;

      return (
        <div key={key} style={{ width: `${(0.98 / currentSlide.content.length) * 100}%` }}>
          {/* MATH HERE: less than 1 to leave space divided by total to get size of each bitton */}
          <Button
            variant={'outlined'}
            disabled={activeButton && !isActive}
            style={{
              width: '100%',
              backgroundColor: isActive ? '#d4d4d4' : ''
            }}
            onClick={() => {
              // videoNavigationHandler(item);
              setActiveButton(item);
              setIsNextButtonDisabled(false);
            }}
          >
            <h2>{item.questionId}</h2>&nbsp;&nbsp;
            {item.text}
          </Button>
          {item.questionId && isActive && renderQuestionProgressButtons()}
          <p>{item.caption}</p>
        </div>
      );
    });
  };

  const renderSidebar = () => {
    if (pollingEnabled && sideBarOpen) {
      if (!questionsLoaded) {
        return <LoadingComponent />;
      }
      return <Sidebar setSideBarOpen={setSideBarOpen} slide={currentSlide} sessionID={sessionID} />;
    }
    return <></>;
  };

  const forwardBackButtonStyle = isForwardBackActive
    ? { cursor: 'pointer', color: 'blue' }
    : { cursor: 'default', color: 'grey' };

  return (
    <div className="flex" style={{ height: '100%' }}>
      <div className={styles['presenter-controller-body']}>
        <div className={styles['play-and-pause-buttons']}>
          <Replay10
            fontSize="large"
            onClick={() => skipInVideo(false)}
            style={forwardBackButtonStyle}
          />
          <PauseCircleOutlineOutlined
            fontSize="large"
            onClick={() => toggleVideoPlaying(false)}
            style={{ cursor: 'pointer', color: isVideoPlaying ? 'black' : 'blue' }}
          />

          <PlayCircleFilledWhiteOutlined
            fontSize="large"
            onClick={() => toggleVideoPlaying(true)}
            style={{ cursor: 'pointer', color: isVideoPlaying ? 'blue' : 'black' }}
          />
          <Forward10
            fontSize="large"
            onClick={() => skipInVideo(true)}
            style={forwardBackButtonStyle}
          />
        </div>

        <div className={styles['presenter-innerbody-topbox']}>
          {!sideBarOpen && (
            <OpenInNewIcon
              className={styles['sidebar-open-icon']}
              sx={{ '&:hover': { color: 'blue', cursor: 'pointer' } }}
              onClick={() => {
                setSideBarOpen(true);
              }}
            />
          )}

          <div className={styles['presenter-innerbody-header']}>
            <p>{currentSlide.title}</p>
            <p>{currentSlide.header}</p>
          </div>
          <div>
            <p style={{ textAlign: 'center' }}>{currentSlide.subHeader}</p>
            <Stack direction="row" justifyContent={'space-between'}>
              {renderCenterButtons()}
            </Stack>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: currentSlide.bottomNavigation.length > 1 ? 'space-between' : 'center'
          }}
        >
          {renderBottomNavigationButtons()}
        </div>
        <div className={styles['presenter-controller-footer']}>
          <ViewModalButton endProgram>
            <Button variant="outlined">End Program</Button>
          </ViewModalButton>
        </div>
      </div>
      {renderSidebar()}
    </div>
  );
};

export default ControllerSlide;
