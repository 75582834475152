import React from 'react';
import styles from '../../styles/AttendeeStyles.module.scss';
import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';
import logosrc from '../../assets/cosetnyx-logo@2x.png';

const WaitingScreen = ({ attendeeStyles }) => {
  return (
    <div
      className={`${styles.centeredScreen} flex-center direction-column waiting-screen-container`}
      style={{ '--header-color': attendeeStyles.headerColor }}
    >
      <h1
        style={{
          marginTop: 'auto',
          marginBottom: 'auto',
          fontSize: '60px',
          fontFamily: 'Flama-Bold'
        }}
      >
        Questions will appear shortly.
      </h1>
      <div className={styles.waitingScreenLogo}>
        <img alt="" src={logosrc} />
      </div>
    </div>
  );
};

export default WaitingScreen;
