import React from 'react';
import SingleMobileAccordion from '../../../common/SingleMobileAccordion';

const MobileSessionList = ({ sessions, type, isEvaluationOnly }) => {
  return sessions.map((session, key) => (
    <SingleMobileAccordion
      session={session}
      key={key}
      type={type}
      isEvaluationOnly={isEvaluationOnly}
    />
  ));
};

export default MobileSessionList;
