import React from 'react';
import { useNavigate } from 'react-router-dom';
import _ from 'lodash';
import styles from '../../styles/AttendeeStyles.module.scss';
import logosrc from '../../assets/cosetnyx-logo@2x.png';

const WelcomePage = ({ attendeeStyles }) => {
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.centeredScreen} flex vertical-center direction-column`}
      style={{ '--header-color': '#FFFFFF' }}
    >
      <div className={styles.audienceHeaderLogo}>
        <img alt="" src={logosrc} />
      </div>
      <h1>{attendeeStyles.title}</h1>
      <button
        type="submit"
        style={{
          backgroundColor: attendeeStyles.buttonColor,
          color: attendeeStyles.buttonWordingColor
        }}
        className={styles.attendeeButton}
        onClick={() => navigate('/audience')}
      >
        {attendeeStyles.buttonText}
      </button>
    </div>
  );
};

export default WelcomePage;
