import React, { useState, useRef } from 'react';
import moment from 'moment';

import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Paper,
  TableContainer
} from '@mui/material';
import { CSVLink } from 'react-csv';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import CreateIcon from '@mui/icons-material/Create';
import ViewModalButton from '../../../common/ViewModalButton';
import ViewPopoverButton from '../../../common/ViewPopoverButton';
import config from '../../../config';

// Run query if not return spinning

const SessionTable = ({ sessions, closedSessions, isEvaluationOnly }) => {
  const auth = useSelector((state) => state.auth);
  const [csvData, setCSVData] = useState([]);
  const csvLink = useRef();

  const updateCSVData = (value, callback) => {
    setCSVData(value);
    callback();
  };

  const downloadReport = (selectedSession, type) => {
    console.log('DOWNLOADING REPORT', selectedSession);
    const path = type === 'host-eval' ? 'surveyAnswers' : 'downloadSessionReport';

    config
      .fetch(`${config.baseUrl}/api/${path}/${selectedSession.id}`, {
        headers: auth.headers,
        method: 'GET'
      })
      .then((response) => {
        response.json().then((csvDataRespose) => {
          updateCSVData(csvDataRespose, () => {
            setTimeout(() => {
              csvLink.current.link.click();
            });
          });
        });
      });
  };

  const renderTableHeader = () => {
    if (closedSessions) {
      return (
        <TableRow>
          <TableCell>Date</TableCell>
          <TableCell>Presenter</TableCell>
          <TableCell>Presentation</TableCell>
          <TableCell>Event</TableCell>
          <TableCell>Event ID</TableCell>
          {!isEvaluationOnly && <TableCell>Polling Report</TableCell>}
          <TableCell>Evaluation Report</TableCell>
          <TableCell>Status</TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow>
        <TableCell>Date</TableCell>
        <TableCell>Presenter</TableCell>
        <TableCell>Presentation</TableCell>
        <TableCell>Event</TableCell>
        <TableCell>Event ID</TableCell>
        {!isEvaluationOnly && <TableCell>Presenter Controller</TableCell>}
        <TableCell>Attendee View</TableCell>
        <TableCell>Status</TableCell>
        <TableCell>Edit</TableCell>
      </TableRow>
    );
  };

  const renderTableBody = (session) => {
    if (closedSessions) {
      return (
        <TableRow key={session.id}>
          <TableCell>
            {moment.utc(session.date).format('yyyy-MM-DD')}
            <br />
            {moment.utc(session.date).format('LT')}
          </TableCell>
          <TableCell>{session.presenter}</TableCell>
          <TableCell>{session.deckName}</TableCell>
          <TableCell>{session.location}</TableCell>
          <TableCell>{session.id}</TableCell>
          {!isEvaluationOnly && (
            <TableCell>
              <>
                <Button
                  id="download"
                  style={{
                    opacity: session.hidePollingReport ? 0.5 : 1,
                    pointerEvents: session.hidePollingReport ? 'none' : 'auto'
                  }}
                  onClick={() => !session.hidePollingReport && downloadReport(session, 'polling')}
                >
                  Download
                </Button>
                <CSVLink
                  data={csvData}
                  filename={`Meeting-${session.id}-Report-Polling.csv`}
                  ref={csvLink}
                />
              </>
            </TableCell>
          )}

          <TableCell>
            <>
              <Button
                id="download"
                style={{
                  opacity: session.hideSurveyReport ? 0.5 : 1,
                  pointerEvents: session.hideSurveyReport ? 'none' : 'auto'
                }}
                onClick={() => !session.hideSurveyReport && downloadReport(session, 'host-eval')}
              >
                Download
              </Button>
              <CSVLink
                data={csvData}
                filename={`Meeting-${session.id}-Report-Host-Eval.csv`}
                ref={csvLink}
              />
            </>
          </TableCell>
          <TableCell>Closed</TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow key={session.id}>
        <TableCell>
          {moment.utc(session.date).format('yyyy-MM-DD')}
          <br />
          {moment.utc(session.date).format('LT')}
        </TableCell>
        <TableCell>{session.presenter}</TableCell>
        <TableCell>{session.deckName}</TableCell>
        <TableCell>{session.location}</TableCell>
        <TableCell>{session.id}</TableCell>
        {!isEvaluationOnly && (
          <TableCell>
            <ViewPopoverButton sessionID={session.id} type={'presenter'}>
              <Button variant={'outlined'}>View</Button>
            </ViewPopoverButton>
          </TableCell>
        )}
        <TableCell>
          <ViewPopoverButton sessionID={session.id} type={'audience'}>
            <Button variant={'outlined'}>View</Button>
          </ViewPopoverButton>
        </TableCell>
        <TableCell>
          {session && session.status === 1 && 'Not Live'}
          {session && session.status === 2 && 'Live'}
        </TableCell>
        <TableCell>
          <ViewModalButton session={session}>
            <CreateIcon color="disabled" className={'editButton'} />
          </ViewModalButton>
        </TableCell>
      </TableRow>
    );
  };

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>{renderTableHeader()}</TableHead>
        <TableBody>{sessions.map((session) => renderTableBody(session))}</TableBody>
      </Table>
    </TableContainer>
  );
};

export default SessionTable;
