import React, { useState } from 'react';
import { ListItemText, ListItemIcon, ListItemButton, List, Collapse, Button } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
// import StarBorder from '@mui/icons-material/StarBorder';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';

import styles from '../styles/ControllerSideBar.module.scss';

const ControllerSidebarButton = ({ slide, navigationHandler }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((prev) => !prev);
  };

  return (
    <>
      <ListItemButton onClick={handleClick}>
        <ListItemText primary={slide.title} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit sx={{ margin: '0px 5px 20px 5px' }}>
        <div className="flex" style={{ justifyContent: 'space-between', margin: '20px 30px' }}>
          <Button
            variant={'outlined'}
            sx={{ width: '45%' }}
            onClick={() => navigationHandler({ ...slide, sideBarClickType: 'start' })}
          >
            Start
          </Button>
          <Button
            variant={'outlined'}
            sx={{ width: '45%' }}
            onClick={() => {
              navigationHandler({
                ...slide,
                sideBarClickType: 'end',
                showSlideOverlay: slide?.content.length > 0
              });
            }}
          >
            End
          </Button>
        </div>
        {slide?.content.map((item, key) => {
          if (slide.showNextButtonOnly) {
            return null;
          }
          return (
            <div key={key}>
              <List component="div" disablePadding>
                <div
                  role="button"
                  tabIndex={0}
                  className={styles['sidebar-content-listitem']}
                  onClick={() => {
                    navigationHandler({ ...item });
                  }}
                >
                  <ListItemIcon>
                    <SubdirectoryArrowRightIcon />
                  </ListItemIcon>
                  <ListItemText primary={item.text} />
                </div>
              </List>
            </div>
          );
        })}
      </Collapse>
    </>
  );
};

export default ControllerSidebarButton;
