import React from 'react';
import { Button, Modal, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';

const EndSessionModal = ({ open, handleClose }) => {
  const navigate = useNavigate();

  const { socket, activeSession } = usePresenterOrAttendee();

  const endSession = () => {
    socket.emit('updateSession', {
      sessionID: activeSession.id,
      status: 3,
      endSession: true,
      room: `${activeSession.id}|attendee`
    });

    navigate('/presenter/thankYou');
  };

  return (
    <Modal open={open} onClose={handleClose} disableScrollLock>
      <Box className="modal-context">
        <Box>
          <p className="modal-header">Are you sure you want to end the program?</p>
          <div className="modal-open-session-buttons">
            <Button variant={'contained'} sx={{ width: '45%' }} onClick={handleClose}>
              Cancel
            </Button>
            <Button variant={'outlined'} sx={{ width: '45%' }} onClick={endSession}>
              End Program
            </Button>
          </div>
        </Box>
      </Box>
    </Modal>
  );
};

export default EndSessionModal;
