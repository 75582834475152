// import moment from 'moment';
import _ from 'lodash';

// export const convertToDateFormat = (date) => {
//   return date.split('T')[0];
// };

export const getSlideByID = (clientConfig, slideID) => {
  return _.find(clientConfig, { id: slideID });
};

export const filterSlides = (clientConfig, filter) => {
  return _.filter(clientConfig, filter);
};

export const filterOnlyChapterSlides = (clientConfig) => {
  return _.filter(clientConfig, { isChapter: true });
};

export const filterOnlyPollingSlides = (clientConfig) => {
  return _.filter(clientConfig, { isPolling: true });
};
