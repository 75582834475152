import React, { useState } from 'react';
import moment from 'moment';

import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import ArrowDropUpOutlinedIcon from '@mui/icons-material/ArrowDropUpOutlined';
import ArrowDropDownOutlinedIcon from '@mui/icons-material/ArrowDropDownOutlined';

import { Button } from '@mui/material';
import ViewPopoverButton from './ViewPopoverButton';
import ViewModalButton from './ViewModalButton';

const SingleMobileAccordion = ({ session, type, isEvaluationOnly }) => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const toggleOpen = () => {
    setOpen((prev) => !prev);
  };

  const renderBody = () => {
    if (open || type === 'attendeeSessions') {
      if (type === 'closedSessions') {
        return (
          <>
            <tr>
              <td>Event ID:</td>
              <td>{session.id}</td>
            </tr>
            <tr>
              <td>Status</td>
              <td>Closed</td>
            </tr>
          </>
        );
      }
      if (type === 'openSessions') {
        return (
          <>
            {!isEvaluationOnly && (
              <tr>
                <td>Presenter Control:</td>
                <td>
                  <ViewPopoverButton sessionID={session.id} type={'presenter'}>
                    <Button variant={'outlined'}>View</Button>
                  </ViewPopoverButton>
                </td>
              </tr>
            )}
            <tr>
              <td>Attendee View:</td>
              <td>
                <ViewPopoverButton sessionID={session.id} type={'audience'}>
                  <Button variant={'outlined'}>View</Button>
                </ViewPopoverButton>
              </td>
            </tr>
            <tr>
              <td>Status:</td>
              <td>
                {session && session.status === 1 && 'Not Live'}
                {session && session.status === 2 && 'Live'}
              </td>
            </tr>
            <tr>
              <td>Edit:</td>
              <td>
                <ViewModalButton session={session}>
                  <EditIcon
                    color="disabled"
                    className={'editButton'}
                    sx={{ '&:hover': { color: 'blue', cursor: 'pointer' } }}
                  />
                </ViewModalButton>
              </td>
            </tr>
          </>
        );
      }
      if (type === 'attendeeSessions') {
        return (
          <tr style={{ textAlign: 'center', verticalAlign: 'middle' }}>
            <td style={{ width: '100%' }} align="center">
              <Button
                variant={'outlined'}
                sx={{ position: 'relative', left: '45%' }}
                onClick={() => navigate(`/audience/${session.id}`)}
              >
                Enter Event
              </Button>
            </td>
          </tr>
        );
      }
    }
  };

  return (
    <div className="accordion-summary-wrapper">
      {type !== 'attendeeSessions' && (
        <div className="mobile-accordion-close">
          {open ? (
            <ArrowDropDownOutlinedIcon color="disabled" onClick={toggleOpen} />
          ) : (
            <ArrowDropUpOutlinedIcon color="disabled" onClick={toggleOpen} />
          )}
        </div>
      )}

      <table className="mobile-accordion-table">
        <tbody>
          <tr onClick={toggleOpen}>
            <td>Event ID:</td>
            <td>{session.id}</td>
          </tr>
          <tr onClick={toggleOpen}>
            <td>Date:</td>
            <td>{moment.utc(session.date).format('YYYY/MM/DD LT')}</td>
          </tr>
          <tr onClick={toggleOpen}>
            <td>Presenter:</td>
            <td>{session.presenter}</td>
          </tr>
          <tr onClick={toggleOpen}>
            <td>Event:</td>
            <td>{session.location}</td>
          </tr>
          <tr onClick={toggleOpen}>
            <td>Presentation:</td>
            <td>{session.deckName}</td>
          </tr>
          {renderBody()}
        </tbody>
      </table>
    </div>
  );
};

export default SingleMobileAccordion;
