import React, { useState } from 'react';

import TimerIcon from '@mui/icons-material/Timer';
import { Button, Stack } from '@mui/material';
import { useTimer } from 'react-timer-hook';

const Timer = ({ children }) => {
  const [timerStarted, setTimerStarted] = useState(false);
  const [timer, setTimer] = useState(0);
  const [showTimesUp, setShowTimesUp] = useState(false);

  const startTimer = (expire) => {
    setTimerStarted(true);
    const time = new Date();
    time.setSeconds(time.getSeconds() + expire); // 1 seconds
    setTimer(time);
  };

  const renderTimerButton = (time) => {
    return (
      <Button
        variant={'outlined'}
        style={{ width: '45%' }}
        disabled={timerStarted}
        onClick={() => {
          startTimer(time);
        }}
      >
        <TimerIcon /> &nbsp;{time}
      </Button>
    );
  };

  return (
    <div style={{ margin: '3vh' }}>
      <Stack direction="row" justifyContent={'space-between'}>
        {renderTimerButton(5)}
        {renderTimerButton(10)}
      </Stack>

      {children}

      {timerStarted && (
        <TimerBody
          expiryTimestamp={timer}
          setTimerStarted={setTimerStarted}
          timerStarted={timerStarted}
          setShowTimesUp={setShowTimesUp}
        />
      )}

      {showTimesUp && (
        <div style={{ textAlign: 'center', marginTop: '3vh' }}>
          <div style={{ border: '1px solid black', fontWeight: 'bold' }}>Times Up!</div>
        </div>
      )}
    </div>
  );
};

const TimerBody = ({ expiryTimestamp, setTimerStarted, timerStarted, setShowTimesUp }) => {
  const { seconds } = useTimer({
    expiryTimestamp,
    onExpire: () => {
      console.warn('Timer Expired');
      setTimerStarted(false);
      setShowTimesUp(true);
      setTimeout(() => setShowTimesUp(false), 3000);

      // eslint-disable-next-line no-alert
    }
  });

  const timerResults = () => {
    return <span style={{ color: seconds <= 3 && 'red' }}>{seconds}</span>;
  };

  return (
    <div style={{ textAlign: 'center', marginTop: '3vh' }}>
      <div style={{ border: '1px solid black', fontWeight: 'bold' }}>
        {timerStarted && timerResults()}
      </div>
    </div>
  );
};

export default Timer;
