import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';
// import { convertToDateFormat } from '../utils/utils';

// eslint-disable-next-line import/no-cycle
import config from '../config';

const dataSlice = createSlice({
  name: 'data',
  initialState: {
    error: false,
    loaded: false,
    loading: false,
    configID: 0,
    clients: {},
    selectedClient: null,
    sessions: []
  },
  reducers: {
    setData(state, action) {
      Object.assign(state, action.payload);
    },
    setError(state, action) {
      state.error = action.payload.error;
    },
    startLoading(state) {
      state.loading = true;
      state.error = null;
    },
    resetData(state) {
      state.error = false;
      state.loaded = false;
      state.loading = false;
      state.configID = 0;
      state.clients = {};
      state.selectedClient = null;
      state.sessions = [];
    }
  }
});

export const { setError, setData, resetData, startLoading } = dataSlice.actions;

export const getClients = () => async (dispatch, getState) => {
  const { auth } = getState();

  config
    .fetch(`${config.baseUrl}/api/clients`, {
      headers: auth.headers,
      method: 'GET'
    })
    .then((response) => {
      response.json().then((clients) => {
        dispatch(
          setData({
            clients: _.keyBy(clients, 'id')
          })
        );
      });
    });
};

export const getSessions = () => (dispatch, getState) => {
  dispatch(setData({ loading: true }));
  const { auth } = getState();

  config
    .fetch(`${config.baseUrl}/api/sessions`, {
      headers: auth.headers,
      method: 'GET'
    })
    .then((response) => {
      response.json().then((sessionData) => {
        const orderby = 'date';
        const direction = 'asc';
        console.log(sessionData);

        const newArray = _.orderBy(sessionData, orderby, direction);

        dispatch(
          setData({
            sessions: newArray,
            loading: false,
            loaded: true
          })
        );
      });
    });
};

export const createNewSession = (date, presenter, location, deckId) => (dispatch, getState) => {
  // begin creating a session
  dispatch(startLoading());
  const { auth, data } = getState();

  console.log('SELECTED CLIENT', data.selectedClient);

  // , 'Content-Type': 'Application-JSON'
  const requestPayload = {
    method: 'POST',
    headers: auth.headers,
    body: JSON.stringify({
      date,
      presenter,
      location,
      status: 1,
      deckId,
      client: data.selectedClient
    })
  };

  config
    .fetch(`${config.baseUrl}/api/sessions`, requestPayload)
    .then((sessionData) => sessionData.json())
    .then((sessionData) => {
      const newData = [...data.sessions, { ...sessionData }];
      console.log(newData);
      // sort
      dispatch(setData({ sessions: newData, loading: false, loaded: true }));
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export const updateSession = (session) => (dispatch, getState) => {
  dispatch(startLoading());
  const { auth, data } = getState();

  const requestPayload = {
    method: 'PUT',
    headers: auth.headers,
    body: JSON.stringify(session)
  };

  config
    .fetch(`${config.baseUrl}/api/sessions/${session.id}`, requestPayload)
    .then((sessionData) => sessionData.json())
    .then((sessionData) => {
      console.log(sessionData);
      const currentSessionIndex = _.findIndex(data.sessions, { id: sessionData.id });
      const newData = [...data.sessions];
      newData[currentSessionIndex] = {
        ...sessionData
      };
      dispatch(setData({ sessions: newData, loading: false, loaded: true }));
    })
    .catch((err) => {
      console.log('err', err);
    });
};

export default dataSlice.reducer;
