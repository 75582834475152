import CloseIcon from '@mui/icons-material/Close';
import PresenterPollingResults from './PollingResults';
import { usePresenterOrAttendee } from '../../../context/AttendeeAndPresenterProvider';

import Timer from '../../../common/Timer';

const Sidebar = ({ setSideBarOpen }) => {
  const { currentPollingStatus } = usePresenterOrAttendee();

  const pollingLive = currentPollingStatus === 2 ? 'LIVE' : 'NOT LIVE';

  return (
    <div className="presenter-controller-sidebar">
      <div
        className="presenter-container-sidebar-body"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '0 2vw',
          borderBottom: '1px solid #aaaaaa',
          alignItems: 'center'
        }}
      >
        <p>POLLING: {pollingLive}</p>
        {/* <CloseIcon
          sx={{ '&:hover': { cursor: 'pointer' } }}
          onClick={() => {
            setSideBarOpen(false);
          }}
        /> */}
      </div>
      <Timer>
        <div className="flex horizontal-center direction-column">
          {/* {currentPollingStatus && (
            <div style={{ margin: '1vh 2vw 0', textAlign: 'center' }}>
              {renderPollingToggleButton()}
            </div>
          )} */}
          <PresenterPollingResults />
        </div>
      </Timer>
    </div>
  );
};

export default Sidebar;
