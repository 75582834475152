import React, { useEffect } from 'react';
import _ from 'lodash';
// import { PlayCircleFilledWhiteOutlined, PauseCircleOutlineOutlined } from '@mui/icons-material';

import LoadingComponent from '../../common/LoadingComponent';
import BeginSessionPage from './BeginSessionPage';
import ControllerSlide from './ControllerSlide';
import ControllerSidebar from './controllerSidebar/ControllerSidebar';
import { getSlideByID, filterOnlyPollingSlides } from '../../utils/utils';
import config from '../../config';
import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';

const { sessionStatus } = config;

const PresenterController = () => {
  const {
    activeSession,
    isSessionStarted,
    sessionID,
    slidesConfig,
    setCurrentSlide,
    socket,
    currentSlide,
    currentPollingStatus,
    isSessionComplete,
    setIsVideoPlaying,
    isForwardBackActive,
    questionsLoaded,
    setQuestionsLoaded
  } = usePresenterOrAttendee();

  const toggleVideoPlaying = (state) => {
    console.log('TOGGLE');
    setIsVideoPlaying(state);

    socket.emit('action', {
      actionType: 'togglePlayingState',
      play: state,
      room: `${activeSession.id}|video`
    });
  };

  const skipInVideo = (state) => {
    if (isForwardBackActive) {
      socket.emit('action', {
        actionType: 'timeSkip',
        time: 10,
        forward: state,
        room: `${activeSession.id}|video`
      });
    }
  };

  const startProgramClick = () => {
    // get the ids of the slide config to construct the pollingSessionStatus table
    const pollingConfigs = filterOnlyPollingSlides(slidesConfig);
    console.log('PollingConfig', pollingConfigs);
    socket.emit('updateSession', {
      sessionID: activeSession.id,
      status: 2,
      beginSession: true,
      pollingConfigs,
      room: `${activeSession.id}|attendee`
    });
  };

  const updateSelectedOption = async (item) => {
    // we know a sub button was pressed

    if (!item) {
      return Promise.resolve();
    }

    const requestPayload = {
      sessionID,
      ignoreNextInline: item.ignoreNextInline,
      questionId: item.questionId,
      questionGroupId: item.questionGroupId
    };

    console.log(requestPayload);

    await fetch(`${config.baseUrl}/api/updateQuestionStatus`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestPayload)
    }).then((res) => {});
  };

  const videoNavigationHandler = async (item, forceCurrentSlideUpdate) => {
    // go to target time tell socket
    console.log('Go to slide', item);
    item.room = `${sessionID}|video`;
    item.actionType = 'seekToTargetTime';
    item.sessionID = sessionID;

    let startOrEndTime = item.sideBarClickType === 'start' ? item.startTime : item.endTime;
    if (
      !item.endTime &&
      item.hotSpotTime &&
      item.hotSpotTime > 0 &&
      item.sideBarClickType !== 'start'
    ) {
      startOrEndTime = item.hotSpotTime + (item.stopDelay || 0);
    }

    if (item.sideBarClickType) {
      item.autoPlay = item.sideBarClickType === 'start';
      item.startTime = startOrEndTime;
    }

    if (item.isPolling && item.requiresPollingQuestionText) {
      // if its polling and the question text is not set, get it from the server

      const requestPayload = {
        questionGroupId: item.questionGroupId,
        sessionID,
        questionIds:
          item.pollingQuestionOptions || item.content.map((content) => content.questionId)
      };

      if (item.fixedQuestionId) {
        // if we have a fixed question id, add it to the front of the array
        requestPayload.questionIds = [item.fixedQuestionId].concat(requestPayload.questionIds);
      }

      const request = await fetch(`${config.baseUrl}/api/getPollingQuestions`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(requestPayload)
      });

      const responseData = await request.json();
      // set the question text on the content items
      item.content.forEach((contentItem, idx) => {
        const questionItem = responseData.pollingQuestions[idx];

        contentItem.text = questionItem.question;
        contentItem.questionId = questionItem.questionId;
        contentItem.isContent = true;
        contentItem.ignoreNextInline = currentSlide.ignoreNextInline;
        contentItem.questionGroupId = item.questionGroupId;
        contentItem.assocSlides = slidesConfig.filter((slide) => {
          return _.includes(slide.assocSlides, questionItem.optionId);
        });
        // find the slide with the optionId and update the content with the starttime

        if (questionItem.optionId) {
          const optionIdSlide = slidesConfig.find(
            (slide) => slide.optionId === questionItem.optionId
          );
          if (optionIdSlide) {
            contentItem.startTime = optionIdSlide.startTime;
          } else {
            console.error('COULD NOT FIND optionIdSlide: ', questionItem.optionId);
          }
        }
      });
      // this was set to false but it would cause the content to not update if the video restarts
      item.needsContentUpdate = true;
      setQuestionsLoaded(true);
    } else if (item.isContent) {
      updateSelectedOption(item);
    }

    socket.emit('action', item);

    // If polling is open, CLOSE
    if (currentPollingStatus === 2 && !item.isChapter) {
      socket.emit('updatePollingState', {
        sessionID,
        slideConfigID: currentSlide.id,
        room: `${sessionID}|attendee`,
        newPollingStatus: 3
      });
    }

    // If we are changing the slideID
    if (item.isChapter && (item.id !== currentSlide.id || forceCurrentSlideUpdate)) {
      setCurrentSlide(getSlideByID(slidesConfig, item.id));
    }
  };

  if (!activeSession) return <LoadingComponent />;
  // Waiting to start

  if (isSessionComplete) {
    return <div className="flex-center">This Session has been closed</div>;
  }

  if (activeSession.status === sessionStatus.deletedStatus) {
    return <div className="flex-center">This Session has been deleted</div>;
  }

  if (!isSessionStarted) {
    return <BeginSessionPage startProgramClick={startProgramClick} />;
  }

  return (
    <div style={{ postion: 'relative', height: '100%' }}>
      <ControllerSidebar
        style={{ position: 'absolute', zIndex: '100' }}
        videoNavigationHandler={videoNavigationHandler}
      />
      <ControllerSlide
        videoNavigationHandler={videoNavigationHandler}
        toggleVideoPlaying={toggleVideoPlaying}
        skipInVideo={skipInVideo}
        updateSelectedOption={updateSelectedOption}
      />
    </div>
  );
};

export default PresenterController;
