import React, { useState } from 'react';
import _ from 'lodash';
import { List, ListSubheader, Drawer, Button } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ControllerSidebarButton from '../../../common/ControllerSidebarButton';
import { filterOnlyChapterSlides } from '../../../utils/utils';
import { usePresenterOrAttendee } from '../../../context/AttendeeAndPresenterProvider';

const ControllerSidebar = ({ style, videoNavigationHandler }) => {
  const { slidesConfig } = usePresenterOrAttendee();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const toggleDrawer = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const navigationHandler = (slideAndType) => {
    videoNavigationHandler(slideAndType);
    toggleDrawer();
  };

  const navigationSlides = filterOnlyChapterSlides(slidesConfig);

  return (
    <div style={{ ...style }}>
      <Button onClick={toggleDrawer}>
        <ArrowForwardIosIcon />
      </Button>
      <Drawer
        open={sidebarOpen}
        onClose={toggleDrawer}
        PaperProps={{
          sx: { width: '20%', minWidth: 300 }
        }}
      >
        <List
          subheader={
            <ListSubheader component="div" id="nested-list-subheader">
              Navigation
            </ListSubheader>
          }
        >
          {navigationSlides.map((slide, key) => (
            <div key={key}>
              <ControllerSidebarButton slide={slide} navigationHandler={navigationHandler} />
            </div>
          ))}
        </List>
      </Drawer>
    </div>
  );
};

export default ControllerSidebar;
