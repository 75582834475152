import React from 'react';
import { Button, InputLabel, TextField } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm } from 'react-hook-form';

const EventSignIn = () => {
  const { register, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // TODO add a redux value to keep person session
  // It will hold all info of current request

  const sendLoginForm = (data) => {
    // TODO Validate sessionid
    // validate access
    // check status is open

    navigate(`${location.pathname}/${data.sessionID}`);
  };

  return (
    <div className="regPage-wrapper flex-center ">
      <form onSubmit={handleSubmit((data) => sendLoginForm(data))}>
        <div className="flex-center direction-column">
          <TextField
            sx={{ width: '30vw' }}
            id="sessionID"
            label="Event ID"
            variant="outlined"
            {...register('sessionID')}
          />

          <Button variant={'contained'} type="submit">
            Enter Event
          </Button>
        </div>
      </form>
    </div>
  );
};

export default EventSignIn;
