import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { styled } from '@mui/material/styles';

import {
  Button,
  IconButton,
  Select,
  MenuItem,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Typography
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';

import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { logoutUser } from '../redux/authSlice';
import { setData, resetData } from '../redux/dataSlice';

const ClientItem = styled(Paper)(({ theme }) => ({
  'backgroundColor': '#E7EBEF',
  ...theme.typography.body2,
  'padding': theme.spacing(1),
  'textAlign': 'center',
  'color': theme.palette.text.secondary,
  'minHeight': 200,
  'display': 'flex',
  'alignItems': 'center',
  'justifyContent': 'center',
  'cursor': 'pointer',
  'boxShadow': theme.shadows[1],
  '&:hover': {
    backgroundColor: '#bcbfc2',
    boxShadow: theme.shadows[5]
  }
}));

const NavBar = () => {
  const [clientSelectModalOpen, setClientSelectModalOpen] = useState(false);

  const user = useSelector((state) => state.auth.user);
  const { selectedClient, clients } = useSelector((state) => state.data);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const isNotDashboard =
    window.location.pathname.includes('audience') || window.location.pathname.includes('presenter');

  useEffect(() => {
    if (clients && !clients[selectedClient]) {
      console.log('clients', clients);
      // setClient(clients[selectedClient]);
      setClientSelectModalOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clients, selectedClient]);

  const signOutButtonClick = () => {
    console.log('Logging out');
    dispatch(logoutUser());
    dispatch(resetData());
    navigate('/auth/login');
  };

  const updateSelectedClient = (newSelectedClient) => {
    dispatch(setData({ selectedClient: newSelectedClient }));
    // dispatch(updateData({ user: { ...user, client: newSelectedClient } }));
  };

  const toggleClientSelectModal = () => {
    setClientSelectModalOpen(!clientSelectModalOpen);
  };

  const renderClientSelectModal = () => {
    return (
      <Dialog open={clientSelectModalOpen} sx={{ margin: 5 }} fullScreen>
        <DialogContent sx={{ textAlign: 'center' }}>
          <div className="flex-center">
            <Typography variant="h4">Please select one.</Typography>
          </div>
          <Grid
            container
            justifyContent={'center'}
            alignItems={'center'}
            spacing={2}
            sx={{ height: '80%' }}
          >
            {clients &&
              _.values(clients).map((clientObj) => {
                return (
                  <Grid item xs={10} md={3} key={clientObj.id}>
                    <ClientItem
                      type={'button'}
                      tabIndex={0}
                      onClick={() => {
                        updateSelectedClient(clientObj.id);
                        toggleClientSelectModal();
                      }}
                    >
                      <Typography variant="h4">
                        {clientObj.attendeeStyles.friendlyName || clientObj.name}
                      </Typography>
                    </ClientItem>
                  </Grid>
                );
              })}
          </Grid>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className="nav-wrapper">
      {!isNotDashboard && user ? (
        <>
          {renderClientSelectModal()}
          <div className="flex">
            <IconButton onClick={toggleClientSelectModal}>
              <HomeIcon sx={{ fontSize: 48 }} color="primary" />
            </IconButton>
            {clients[selectedClient] && (
              <h1 style={{ paddingLeft: '35px' }}>
                {clients[selectedClient].attendeeStyles.friendlyName ||
                  clients[selectedClient].name}
              </h1>
            )}
          </div>

          <Button variant={'contained'} sx={{ margin: '1vh 1vw' }} onClick={signOutButtonClick}>
            Log Out
          </Button>
        </>
      ) : (
        ''
      )}
    </div>
  );
};

export default NavBar;
