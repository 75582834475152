import React, { useState } from 'react';
import { Popover, Button } from '@mui/material';
import { Link } from 'react-router-dom';

const ViewPopoverButton = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [sessionPath, setSessionPath] = useState('');

  const handlePopoutClick = (e) => {
    setSessionPath(`/${props.type}/${props.sessionID}`);
    setAnchorEl(e.currentTarget);
  };

  const handlePopoutClose = () => {
    setAnchorEl(null);
  };

  const renderPopOver = () => {
    const popOverOpen = Boolean(anchorEl);
    const popOverId = popOverOpen ? 'simple-popover' : undefined;
    return (
      <Popover
        id={popOverId}
        open={popOverOpen}
        anchorEl={anchorEl}
        onClose={handlePopoutClose}
        disableScrollLock
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'center'
        }}
      >
        <div className="home-table-popout">
          <Button
            variant={'contained'}
            onClick={() => {
              navigator.clipboard.writeText(`${window.location.host}${sessionPath}`);
            }}
          >
            Copy Url
          </Button>

          <Link to={sessionPath} target="_blank" style={{ textDecoration: 'none', color: 'black' }}>
            <Button variant={'outlined'} sx={{ width: '100%' }}>
              Open
            </Button>
          </Link>
        </div>
      </Popover>
    );
  };

  return (
    <div>
      {renderPopOver()}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={handlePopoutClick}>{props.children}</div>
    </div>
  );
};

export default ViewPopoverButton;
