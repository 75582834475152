import { useEffect, useState } from 'react';
import { BrowserRouter as Router, useRoutes } from 'react-router-dom';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';
import Home from './dashboard/home/Home';
import '../styles/App.scss';
import NavBar from './NavBar';
import LoginPage from './dashboard/auth/LoginPage';
// eslint-disable-next-line import/no-named-as-default
import config from '../config';
import EventSignIn from './presenter/EventSignIn';
import PresenterView from './presenter';
import PresenterThankYou from './presenter/PresenterThankYou';
import AttendeeView from './attendee';
import AttendeeThankYou from './attendee/AttendeeThankYou';
import AttendeeLandingPage from './attendee/AttendeeLandingPage';
// import AttendeeHostEval from './attendee/AttendeeHostEval';

const aresTheme = createTheme({
  typography: {
    fontFamily: "'Avenir', 'Roboto', 'Helvetica', sans-serif",
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500
  },
  palette: {
    blue: {
      main: '#004866',
      contrastText: '#fff'
    },
    turquoise: {
      // main: '#167380',
      main: '#189A94',
      contrastText: '#fff'
    },
    // text: {
    //   primary: '#000',
    //   white: '#FFF',
    //   // turquoise: '#167380',
    //   turquoise: '#189A94'
    // }
    components: {
      MuiButton: {
        styleOverrides: {
          outlined: {
            backgroundColor: '#167380'
          }
        }
      }
    }
  },
  overrides: {
    // MuiInputLabel: {
    //   root: {
    //     opacity: '1',
    //     color: '#000'
    //   }
    // },
    // MuiFormLabel: {
    //   root: {
    //     color: '#000'
    //   }
    // }
  }
});

const presenter = 'presenter';
const audience = 'audience';

const AppRouter = () => {
  const routes = useRoutes([
    { path: '/', element: <Home /> },
    { path: '/auth/login', element: <LoginPage /> },
    { path: `/${presenter}`, element: <EventSignIn /> },
    {
      path: `/${presenter}/:sessionID`,
      element: <PresenterView />,
      key: uuidv4()
    },
    { path: `/${audience}`, element: <AttendeeLandingPage /> },
    {
      path: `/${audience}/:sessionID`,
      element: <AttendeeView />,
      key: uuidv4()
    },
    {
      path: `/${presenter}/thankYou`,
      element: <PresenterThankYou />
    },
    { path: '*', element: <Home /> }
  ]);
  return routes;
};

const CustomLinkRouter = () => {
  const routes = useRoutes([
    { path: '/', element: <AttendeeLandingPage /> },
    {
      path: '/:sessionID',
      element: <AttendeeView />,
      key: uuidv4()
    },
    {
      path: `/${audience}/thankYou`,
      element: <AttendeeThankYou />
    },
    { path: '*', element: <AttendeeLandingPage /> }
  ]);
  return routes;
};

const App = () => {
  const auth = useSelector((state) => state.auth);
  const [useCustomLink, setUseCustomLink] = useState(false);
  const [showNavBar, setShowNavBar] = useState(true);

  useEffect(() => {
    // Check for all cases
    // We don't need to check headers for presenter / attendee / custom link

    // customLink
    const currentWindowPath = window.location.pathname;

    const validPresenterOrAttendeeURL =
      currentWindowPath.match(/(presenter)\/?(\d+)?/) ||
      currentWindowPath.match(/(audience)\/?(\d+)?/);

    // If valid attendee or presenter link
    if (validPresenterOrAttendeeURL) {
      console.log(validPresenterOrAttendeeURL[1]);
      if (validPresenterOrAttendeeURL[1] === audience) {
        setShowNavBar(false);
      }

      if (validPresenterOrAttendeeURL[0] !== currentWindowPath) {
        // eslint-disable-next-line prefer-destructuring
        // console.log(window.location);
        // eslint-disable-next-line prefer-destructuring
        // window.location.pathname = validPresenterOrAttendeeURL[0];
        // window.location.href =
        //   window.location.protocol + window.location.hostname + validPresenterOrAttendeeURL[0];
      }
      // Validate id?
      return;
    }

    if (window.location.pathname !== '/auth/login') {
      config.fetch('/auth/check-auth', { headers: auth.headers });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ThemeProvider theme={aresTheme}>
      <Router>
        {showNavBar && <NavBar />}
        {/* {!useCustomLink ? <AppRouter /> : <CustomLinkRouter />} */}
        <AppRouter />
      </Router>
    </ThemeProvider>
  );
};

export default App;
