import React from 'react';
import _ from 'lodash';
import { InputLabel, MenuItem, FormControl, Select, Box } from '@mui/material';

const DeckSelectDropdown = ({
  selectedDeckId,
  setSelectedDeck,
  width = 200,
  clientDeck,
  deckStyles,
  children
}) => {
  const handleChange = (event) => {
    const newSelectedDeck = _.find(clientDeck, { id: event.target.value });
    setSelectedDeck(newSelectedDeck);
  };

  return (
    <div className="flex" style={deckStyles}>
      {children}
      <Box sx={{ minWidth: width }}>
        <FormControl fullWidth>
          <InputLabel id="deck-select-label">Client Deck</InputLabel>
          <Select
            labelId="deck-select-label"
            // disabled={clientDeck.length === 1}
            id="video-select"
            value={selectedDeckId}
            label="Selected Deck"
            onChange={handleChange}
          >
            {clientDeck.map((media) => (
              <MenuItem value={media.id} key={media.id}>
                {media.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
    </div>
  );
};

export default DeckSelectDropdown;
