import { AttendeeAndPresenterProvider } from '../../context/AttendeeAndPresenterProvider';
import PresenterController from './PresenterController';

const PresenterView = () => {
  return (
    <AttendeeAndPresenterProvider type={'presenter'}>
      <PresenterController />
    </AttendeeAndPresenterProvider>
  );
};

export default PresenterView;
