import React from 'react';
import { Box } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';

const LoadingComponent = () => {
  return (
    <div className="flex-center">
      <CircularProgress />
    </div>
  );
};

export default LoadingComponent;
