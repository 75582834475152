import { createSlice } from '@reduxjs/toolkit';

const baseUrl = process.env.REACT_APP_APIURL;

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    user: null,
    accessToken: null,
    refreshToken: null,
    error: null,
    loading: false,
    headers: null
  },
  reducers: {
    updateData(state, action) {
      Object.assign(state, action.payload);
    },
    startLoading(state) {
      state.loading = true;
      state.error = null;
    },
    loginUserFail(state, action) {
      state.error = action.payload.error;
      state.loading = false;
    },
    loginUserSuccess(state, action) {
      Object.assign(state, action.payload);
      state.loading = false;
      state.error = null;
    },
    logoutUser(state) {
      console.log('logoutUser');
      state.user = null;
      state.accessToken = null;
      state.refreshToken = null;
      state.headers = null;
    }
  }
});

export const { loginUserSuccess, loginUserFail, startLogin, logoutUser, updateData, startLoading } =
  authSlice.actions;

export default authSlice.reducer;

export const loginUser = (email, password, navigate) => {
  return async (dispatch) => {
    // start the user login process
    dispatch(startLoading());
    const requestPayload = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ email, password })
    };

    fetch(`${baseUrl}/auth/login`, requestPayload)
      .then((request) => {
        if (request.status !== 200) {
          dispatch(
            loginUserFail({
              error: 'Wrong username/password combination'
            })
          );
          // ReactGA.event({
          //   category: 'Auth',
          //   action: 'Login Unsuccessful'
          // });
          return;
        }
        // ReactGA.event({
        //   category: 'Auth',
        //   action: 'Login Successful'
        // });
        return request.json();
      })
      .then((response) => {
        if (!response) {
          return;
        }
        if (process.env.NODE_ENV === 'production') {
          // Sentry.setUser({
          //   id: response.user.id,
          //   email: response.user.email
          // });
        }
        dispatch(
          loginUserSuccess({
            accessToken: response.accessToken,
            refreshToken: response.refreshToken,
            user: response.user,
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${response.accessToken}`
            }
          })
        );
        // const next = config.getUrlParams('next');
        // if (next) {
        //   return navigate(next);
        // }
        return navigate(`/${window.location.search}`);
      })
      .catch((error) => {
        console.log('Login Error:', error);
        return dispatch(
          loginUserFail({
            error: 'Something went wrong. Please try again later.'
          })
        );
      });
  };
};

// export const updatePassword = (newPassword, password) => {
//   return async (dispatch, getState) => {
//     const { auth } = getState();
//     dispatch(startLoading());
//     const requestPayload = {
//       method: 'PUT',
//       headers: auth.headers,
//       body: JSON.stringify({
//         newPassword: newPassword,
//         password: password
//       })
//     };
//     fetch(`${config.baseApiUrl}/auth/user/updatepwd`, requestPayload).then((request) => {
//       if (request.status !== 200) {
//         dispatch(updateData({ loading: false }));
//         alert('There was an error with your request. Please reenter your password');
//         return;
//       }
//       return dispatch(
//         updateData({ loading: false, user: { ...auth.user, completed_registration: true } })
//       );
//     });
//   };
// };

// export const SignOutUser = () => {
//   return async (dispatch) => {
//     dispatch(logoutUser());
//   };
// };

// export const checkUserAuth = (navigate) => {
//   return async (getState) => {
//     const { auth } = getState();
//     // ensure headers exists
//     if (auth.headers) {
//       // if they do make sure token is valid
//       const requestPayload = {
//         method: 'GET',
//         headers: auth.headers
//       };

//       fetch(`${baseUrl}/api/checkuserauth`, requestPayload)
//         .then((request) => {
//           console.log('CHECK AUTH RETURN', request.status);
//           navigate('/');
//         })
//         .catch((err) => {
//           console.log('ERROR', err);
//           navigate('/login');
//         });
//     } else {
//       navigate('/login');
//     }
//   };
// };
