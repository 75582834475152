import { AttendeeAndPresenterProvider } from '../../context/AttendeeAndPresenterProvider';
import AttendeeController from './AttendeeController';

const AttendeeView = () => {
  return (
    <AttendeeAndPresenterProvider type={'attendee'}>
      <AttendeeController />
    </AttendeeAndPresenterProvider>
  );
};

export default AttendeeView;
