import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import CreateEditSessionModal from './modals/CreateEditSessionModal';
import EndSessionModal from './modals/EndSessionModal';
import SessionStatusModal from './modals/SessionStatusModal';

const ViewModalButton = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const { clients, selectedClient } = useSelector((state) => state.data);

  // console.log('client', client);

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const displayModal = () => {
    if (props.statusModal) {
      return (
        <SessionStatusModal
          handleClose={toggleModalOpen}
          selectedSession={props.session}
          open={modalOpen}
        />
      );
    }
    if (props.endProgram) {
      return <EndSessionModal handleClose={toggleModalOpen} open={modalOpen} />;
    }
    if (modalOpen && props.session) {
      return (
        <CreateEditSessionModal
          handleClose={toggleModalOpen}
          session={props.session}
          open={modalOpen}
          client={clients[selectedClient]}
        />
      );
    }
  };

  return (
    <div>
      {displayModal()}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div onClick={toggleModalOpen}>{props.children}</div>
    </div>
  );
};

export default ViewModalButton;
