/* eslint-disable indent */

import React, { useState } from 'react';
import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';

import styles from '../../styles/AttendeeStyles.module.scss';
import logosrc from '../../assets/cosetnyx-logo@2x.png';

const AttendeePolling = ({ attendeeStyles }) => {
  console.log(attendeeStyles);
  const { currentSlide, sessionID, socket, setCurrentPollingStatus } = usePresenterOrAttendee();
  const [submitted, setSubmitted] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);

  // const [optionId, setOptionId] = useState();

  const handleChange = (event) => {
    setSelectedOption(parseInt(event.currentTarget.value, 10));
  };

  const pollingSelectClick = () => {
    // The answer ID is stored in the key of data

    const pollingOptions = currentSlide.content;

    const selectedOptions = [];
    // loop through content. Check for ID store value
    // if (currentSlide.multiSelect) {
    //   pollingOptions.forEach((content) => {
    //     // If box was checked, values is true
    //     if (data[content.id]) {
    //       selectedOptions.push({ id: content.id, text: content.text });
    //     }
    //   });
    // } else if (optionId) {
    selectedOptions.push({
      id: selectedOption,
      text: pollingOptions.find((option) => option.id === selectedOption).text
    });

    if (selectedOption === 0) {
      // PLEASE select
      return;
    }

    const storedValue = localStorage.getItem('UNIQUEID') || 0;

    const valuesToBeSent = {
      sessionID,
      slideConfigID: currentSlide.id,
      attendeeID: storedValue,
      slideTitle: currentSlide.title,
      attendeeAnswers: selectedOptions
    };

    socket.emit('audiencePolling', valuesToBeSent);
    setCurrentPollingStatus(1);
    // setSubmitted(true);
  };

  const loadAttendeeFormOptions = () => {
    return currentSlide.content.map((option, key) => {
      const selected = option.id === selectedOption;
      const imgType = option.pollingType === 'image';

      const additionStyles = imgType
        ? {}
        : {
            '--button-color': selected ? attendeeStyles.selected : attendeeStyles.notSelected,
            '--border-color': attendeeStyles.buttonBorderColor,
            '--text-color': selected
              ? attendeeStyles.buttonTextColorSelected
              : attendeeStyles.buttonTextColorNotSelected,
            'fontFamily': selected ? 'Flama-Bold' : 'Flama-Basic'
          };

      const className = option?.image ? styles.pollingImgButton : styles.pollingButton;

      return (
        <div key={key}>
          <button
            value={option.id}
            onClick={handleChange}
            type="submit"
            className={`${styles.pollingButtonDefault} ${className}`}
            style={additionStyles}
          >
            {option?.image ? (
              <img
                src={option.image}
                alt=""
                className={styles.pollingImg}
                style={{
                  '--border-color': selected ? 'yellow' : 'transparent'
                }}
              />
            ) : (
              option.text
            )}
          </button>
        </div>
      );
    });
  };

  if (submitted) {
    return (
      <div className="flex-center direction-column">
        <h1>Answer Submitted!</h1> <p>Thank you for submitting.</p>
      </div>
    );
  }
  console.log(attendeeStyles);
  return (
    <div
      className={`${styles.centeredScreen} flex direction-column vertical-center`}
      style={{
        'marginTop': '15px',
        'justifyContent': 'space-between',
        '--header-color': attendeeStyles.headerColor,
        '--subheader-color': attendeeStyles.subHeaderColor
        // 'marginBottom': '100px'
      }}
    >
      <div className={styles.pollingBody}>
        <h1 style={{ color: 'white', fontSize: 38 }}>{currentSlide.pollingTitle}</h1>
        <p style={{ fontSize: 28, marginTop: 50 }}>Select an Option</p>
        <div>
          {loadAttendeeFormOptions()}

          <button
            type="submit"
            className={styles.pollingButton}
            disabled={!selectedOption}
            style={{
              '--button-color': attendeeStyles.buttonColor,
              '--text-color': attendeeStyles.buttonWordingColor,
              'fontFamily': 'Flama-Bold',
              'display': 'inline-flex',
              'align-items': 'center',
              'justify-content': 'center'
            }}
            onClick={pollingSelectClick}
          >
            Submit
          </button>
        </div>
      </div>
      <div className={styles.waitingScreenLogo}>
        <img src={logosrc} alt="" />
      </div>
    </div>
  );
};

export default AttendeePolling;
