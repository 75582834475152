import React from 'react';
import {
  Button,
  Box,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody
} from '@mui/material';
// import { convertToDateFormat } from '../utils/utils';

const DisplaySingleSession = ({ session, style }) => {
  return (
    <TableContainer component={Paper} sx={style}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell align="left">Date</TableCell>
            <TableCell align="left">Presenter</TableCell>
            <TableCell align="left">Event</TableCell>
            <TableCell align="left">Event ID</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
            <TableCell align="left">{session.date}</TableCell>
            <TableCell align="left">{session.presenter}</TableCell>
            <TableCell align="left">{session.location}</TableCell>
            <TableCell align="left">{session.id}</TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DisplaySingleSession;
