import React from 'react';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import DisplaySingleSession from '../../common/DisplaySingleSession';

import { usePresenterOrAttendee } from '../../context/AttendeeAndPresenterProvider';

const BeginSessionPage = ({ startProgramClick }) => {
  const { activeSession } = usePresenterOrAttendee();
  const navigate = useNavigate();

  return (
    <div className="flex-center direction-column" style={{ gap: '40px', height: '100%' }}>
      <Button sx={{ position: 'absolute', left: '3%', top: '5%' }} onClick={() => navigate(-1)}>
        Back
      </Button>
      <DisplaySingleSession session={activeSession} style={{ width: '80%' }} />
      <Button variant={'contained'} onClick={() => startProgramClick()}>
        Start Program
      </Button>
    </div>
  );
};

export default BeginSessionPage;
